  import { Injectable } from '@angular/core';
  import { HttpClient } from '@angular/common/http';
  import { Observable } from 'rxjs';
  import { environment } from '@environments/environment';
  import { ICalendarResponse } from '../interfaces';

  @Injectable({
    providedIn: 'root'
  })

  export class ApiService {
    private apiUrl = environment.apiUrl

    constructor(private httpClient: HttpClient) { }

    public getInitialData(): Observable<Object> {
      return this.httpClient.get(`${this.apiUrl}/boot-data`, { withCredentials: true });
    }
    public getLanguagesCurrenciesTimezones(): Observable<any> {
      return this.httpClient.get(`${this.apiUrl}/languages-currencies-timezones`, { withCredentials: true });
    }
    public getManagers(): Observable<any> {
      return this.httpClient.get(`${this.apiUrl}/managers`, { withCredentials: true });
    }
    public changeLanguage(lang: string): Observable<any> {
      return this.httpClient.get(`${this.apiUrl}/language/` + lang, { withCredentials: true })
    }
    public getContacts(): Observable<any> {
      return this.httpClient.get(`${this.apiUrl}/contacts`, { withCredentials: true })
    }
    public getAssociations(): Observable<any> {
      return this.httpClient.get(`${this.apiUrl}/associations`, { withCredentials: true })
    }
    public getAssociationUnits(associationId: string): Observable<any> {
      return this.httpClient.get(`${this.apiUrl}/associations/${associationId}/units`, { withCredentials: true })
    }
    public getCalendarBookings(num: number, startDay: string): Observable<ICalendarResponse> {
      return this.httpClient.get<ICalendarResponse>(`${this.apiUrl}/bookings/` + num + '/' + startDay, { withCredentials: true })
    }
    public getReservations(): Observable<any> {
      return this.httpClient.get(`${this.apiUrl}/all-bookings`, { withCredentials: true })
    }
    public postDemoRequest(data: any): Observable<any> {
      return this.httpClient.post(`${this.apiUrl}/demos`, data);
    }
  }
